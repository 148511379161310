import { isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
function Node(options) {
    // key
    this.key = options.key;
    // 名称
    this.name = options.name;
    // 路由路径
    this.path = options.path;
    // 父节点
    this.parents = options.parents;
    // 子节点
    this.children = options.children;
    // 当前节点或者后代节点是否有被选中
    this.hasChecked = options.hasChecked;
}

Node.prototype.doChecked = function (hasChecked) {
    let currNode = this.parents;
    while (currNode) {
        currNode.hasChecked = hasChecked;
        currNode = currNode.parents;
    }
}

// 格式化章节目录
export const formatChapters = (Chapters, parents = null) => {
    if (isArray(Chapters)) {
        return Chapters.map(c => {
            let { name, path, children = [] } = c;
            const options = {
                key: uuidv4(),
                name,
                path,
                parents,
                hasChecked: false
            };
            const node = new Node(options);
            if (isArray(children) && children.length > 0) {
                node.children = formatChapters(children, node);
            }
            return node;
        });
    }
    return [];
}

// 格式化侧边栏
export const formatMenusIndex = (treeArr) => {
    const menus = [];
    if (isArray(treeArr)) {
        treeArr.forEach(item => {
            const { name, path, children = [] } = item;
            menus.push({ name, path, key: uuidv4() });
            if (isArray(children) && children.length > 0) {
                menus.push(formatMenusIndex(children));
            }
        });
        return menus.flat();
    }
    return [];
}

// 修改树的状态
export const treeLoop = (treeArr, id) => {
    if (isArray(treeArr)) {
        return treeArr.map(item => {
            let { key, children = [] } = item;
            if (id === key) {
                item.doChecked(true);
                return item;
            }
            if (isArray(children) && children.length > 0) {
                children = treeLoop(children, id);
            }
            item.children = children;
            return item;
        });
    }
    return [];
}

// 重置树状态
export const resetTreeLoop = (treeArr) => {
    if (isArray(treeArr)) {
        return treeArr.map(item => {
            let { children = [] } = item;
            item.hasChecked = false;
            if (isArray(children) && children.length > 0) {
                children = resetTreeLoop(children);
            }
            item.children = children;
            return item;
        });
    }
    return [];
}

export const getSearchObj = () => {
    const hash = window.location.hash;
    const search = hash ? hash.split("?") : []
    const searchObj = qs.parse(search[1] || "", { ignoreQueryPrefix: true });
    return searchObj;
}






