import { useEffect } from 'react';
import createNavList from './navAnchor';

const useNavList = (md) => {

    useEffect(() => {
        createNavList();
    }, [md]);
}

export default useNavList;