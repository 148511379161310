
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd"
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import '@/Language';
import { getCurrentLanguage, Language } from '@/Utils/config';
import Layout from '@/Layout';
import './App.css';


let currlanguage = zhCN;
if (getCurrentLanguage() !== Language.zh_CN) {
  currlanguage = enUS;
}

function App() {
  return (
    <ConfigProvider locale={currlanguage}>
      <div className='app'>
        <Router>
          <Layout />
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
