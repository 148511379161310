import enData from "./en_US"
import zhData from "./zh_CN"
import { config, Language } from "@/Utils/config";

export function translate(value) {
    let languageData = {};
    if (config.language === Language.zh_CN) {
        languageData = zhData
    } else if (config.language === Language.en_US) {
        languageData = enData
    }
    if (typeof value !== "string") {
        console.error("语言key当为字符串");
        return "";
    }
    return languageData[value] || "";
}

export function translateParams(value, ...arg) {
    return translate(value).replace(/\{(\d+)\}/g, function (m, i) {
        return arg[i] || "";
    })
}
