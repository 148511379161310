import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { matchPath } from "react-router";
import ProductContext from '@/Context/ProductContext';
import MenuContext from '@/Context/MenuContext';
import Header from '@/Components/Header';
import Footer from '@/Components/Footer';
import { Routers, URL } from '@/Common/router';
import { getProject } from '@/Utils/api';
import { getProjectSynch as getProjectFromCache } from '@/Utils/storage';
import { translate } from '@/Language/translate';
import { getDocsUrl } from '@/Utils/config';
import useForbidCopy from './useForbidCopy';


import './index.less';


const setDocument = (project) => {
    document.title = project.projectName || translate('SYS_DOC_TITLE');
    if (!project.projectIcon) return
    const link = document.getElementById('icon')
    link.setAttribute('href', `${getDocsUrl()}${project.projectIcon}`);
}

const Layout = (props) => {
    const _project = getProjectFromCache();
    // 获取产品信息
    const [project, setProject] = useState(_project);
    // 菜单显示隐藏
    const [visible, setVisible] = useState(false);

    const match = matchPath(window.location.hash.replace('#', ''), {
        path: URL.product,
        exact: true,
        strict: false
    });

    if (process.env.NODE_ENV === 'production') {
        // 防止拷贝
        useForbidCopy(project);
    }

    useEffect(() => {
        // 没有缓存
        if (!_project.homePath) {
            const product = _.get(match, 'params.product', '');
            const version = _.get(match, 'params.version', '');
            getProject(product, version).then(res => {
                setProject(res);
                setDocument(res);
            });
            return
        }
        setDocument(_project);
    }, []);

    return <div className='page-box'>
        <ProductContext.Provider value={{ project }}>
            <MenuContext.Provider value={{ visible, setVisible }}>
                <Header project={project} />
                <Switch>
                    <Redirect exact from={"/"} to={project.homePath || URL.exception} />
                    {
                        Routers.map(r => <Route key={r.path} {...r} />)
                    }
                </Switch>
                <Footer match={match} />
            </MenuContext.Provider>
        </ProductContext.Provider>
    </div>
}

export default Layout;
