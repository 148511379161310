import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Drawer, Space, Button } from 'antd';
import _ from 'lodash';
import SearchBar, { Search } from '@/Components/SearchBar';
import Menu from '@/Components/Menu';
import ProductContext from '@/Context/ProductContext';
import MenuContext from '@/Context/MenuContext';
import { getSearchObj } from '@/Utils/format';


import './index.less';


const NormalSideBar = (props) => {
    const search = getSearchObj();
    const { project } = useContext(ProductContext);
    if (search.hiddensidebar === "1") return null;

    return <nav className='side-bar-box'>
        <div className='search-bar-container'>
            <SearchBar project={project} />
        </div>
        <Menu />
    </nav>
}

export const MobileSideBar = (props) => {
    const search = getSearchObj();
    const { project } = useContext(ProductContext);
    const { visible, setVisible } = useContext(MenuContext);
    const onClose = useCallback(() => {
        setVisible(false)
    }, []);

    if (search.hiddensidebar === "1") return null;

    return <Drawer
        closable={false}
        width={"80%"}
        placement="left"
        onClose={onClose}
        open={visible}
        title={<Search project={project} showSearchBtn={false} />}
    >
        <nav className='side-bar-box'>
            <Menu />
        </nav>
    </Drawer>
}




export default NormalSideBar