import React, { useState, useEffect, useCallback } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Link from '@/Components/Link';
import { getMenuByProduct } from '@/Utils/api';
import platform from '@/Utils/platform';
import { translate } from '@/Language/translate';


import './index.less';


const getCurrIndex = (currIndex, step, arr = []) => {
    const maxIndex = arr.length - 1, minIndex = 0;
    let _currIndex = currIndex + step;
    let end = _currIndex >= minIndex && _currIndex <= maxIndex;
    while (end) {
        const res = arr[_currIndex] || {};
        if (res.path) {
            return _currIndex;
        }
        _currIndex = _currIndex + step;
        end = _currIndex >= minIndex && _currIndex <= maxIndex;
    }
    return -1;
};

const QLink = (props) => {
    if (props.path) return <Link {...props} />
    return null;
}

const Footer = (props) => {

    const [menu, setMenu] = useState([]);
    const [preChapter, setPreChapter] = useState([]);
    const [nextChapter, setNextChapter] = useState([]);

    const product = _.get(props, 'match.params.product', '');
    const version = _.get(props, 'match.params.version', '');
    const pathname = _.get(props, 'match.url', '');

    if (!platform.isMobile) return null;

    const find = useCallback((_pathname = '', _menu = []) => {
        if (!_.isArray(_menu)) return;
        const index = _menu.findIndex(m => m.path === _pathname);
        if (index != -1) {
            const preCurrIndex = getCurrIndex(index, -1, _menu);
            const nextCurrIndex = getCurrIndex(index, 1, _menu);
            setPreChapter(preCurrIndex > -1 ? _menu[preCurrIndex] : []);
            setNextChapter(nextCurrIndex > -1 ? _menu[nextCurrIndex] : []);
        }

    }, [])


    useEffect(() => {
        if (!product || !version) return
        getMenuByProduct(product, version).then(({ menusIndex }) => {
            setMenu(menusIndex);
            find(pathname, menusIndex);
        });
    }, [product, version]);


    useEffect(() => {
        find(pathname, menu)
    }, [pathname]);


    return <footer className='footer-box'>
        <QLink path={preChapter.path}>
            <div className='pre-chapter'>
                <div className='icon-box'>
                    <ArrowLeftOutlined style={{ fontSize: '24px', color: '#8899a8' }} />
                </div>
                <div className='desc-box'>
                    <div className='title text-right'>
                        {translate('SYS_PRE_CHAPTER')}
                    </div>
                    <div className='content text-right'>
                        {preChapter.name}
                    </div>
                </div>
            </div>
        </QLink>
        <QLink path={nextChapter.path}>
            <div className='next-chapter'>
                <div className='desc-box'>
                    <div className='title'>
                        {translate('SYS_NEXT_CHAPTER')}
                    </div>
                    <div className='content'>
                        {nextChapter.name}
                    </div>
                </div>
                <div className='icon-box'>
                    <ArrowRightOutlined style={{ fontSize: '24px', color: '#8899a8' }} />
                </div>
            </div>
        </QLink>
    </footer>
}

export default Footer;