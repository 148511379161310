
import localforage from 'localforage';
import _ from 'lodash';
/***设置产品列表信息 start */
const DATA_BASE_NAME = "quant-help-book";

// 本地数据库配置
localforage.config({
    driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
    name: DATA_BASE_NAME
});

/**
 * 设置应用信息
 */
const PRODUCT_PROJECT_INFO = "quant-product-info";
export function setProjectSynch(prodInfo) {
    sessionStorage.setItem(PRODUCT_PROJECT_INFO, JSON.stringify(prodInfo));
}

export function getProjectSynch() {
    return JSON.parse(sessionStorage.getItem(PRODUCT_PROJECT_INFO) || '{}');
}

/**
 * 设置语言信息
 */
const PRODUCT_PROJECT_LANG = "quant-product-language";
export function setLang(language) {
    sessionStorage.setItem(PRODUCT_PROJECT_LANG, language);
}
export function getLang() {
    return sessionStorage.getItem(PRODUCT_PROJECT_LANG);
}

/** 设置菜单搜索索引--菜单数据扁平化用于搜索 ***/
const PRODUCT_SIDE_BAR_KEY = "quant-side-bar-search-index";
export function setSearchIndex(menus) {
    localforage.setItem(PRODUCT_SIDE_BAR_KEY, menus);
}

export function getSearchIndex() {
    return localforage.getItem(PRODUCT_SIDE_BAR_KEY);
}
