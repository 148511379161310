import { useEffect, useState } from 'react';

const usePreview = () => {
    const [previewUrl, setPreviewUrl] = useState('');

    useEffect(() => {
        const markdownBody = document.querySelector('.markdown-body');
        if (!markdownBody) return;
        const handler = (evt) => {
            if (evt.target.tagName !== 'IMG') {
                evt.stopPropagation();
                return
            };
            const src = evt.target?.src;
            setPreviewUrl(src)
        }
        markdownBody.addEventListener('click', handler);

        return () => {
            markdownBody.removeEventListener('click', handler);
        }

    }, []);

    return previewUrl;
}

export default usePreview;