import { useEffect } from 'react';

// 禁用视频下载
const useVideo = (md) => {
    useEffect(() => {
        const videos = document.querySelectorAll('.markdown-body video');
        for (let index = 0, len = videos.length; index < len; index++) {
            const video = videos[index];
            if (video) {
                video.setAttribute("controlslist", "nodownload");
            }
        }
    }, [md]);
}

export default useVideo;