import { useEffect } from 'react';

const useHighlightAll = (md) => {

    useEffect(() => {
        window.hljs && window.hljs.highlightAll();
    }, [md]);

}

export default useHighlightAll;