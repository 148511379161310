export default {
    "SYS_COMPANY": "量投科技",
    "SYS_DOC_TITLE": "量投官方文档 | 产品帮助文档",
    "SYS_CANCEL": "取消",
    "SYS_GO_BACK": "返回",
    "SYS_SEARCH_PLACEHOLDER": "搜索",
    "SYS_NOT_FOUND": "对不起，您访问的文档不存在。",
    "SYS_MORE": "更多",
    "SYS_LOADING_ERR": "加载错误",

    "HISTORY_VERSION": "历史版本",
    "HISTORY_LIST": "版本列表",

    "SYS_PRE_CHAPTER": "上一篇",
    "SYS_NEXT_CHAPTER": "下一篇",
}