import React, { useEffect, useState } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import classNames from "classnames";
import { marked } from 'marked';
import { Spin } from 'antd';
import { SplitPane } from 'react-collapse-pane';
import NormalSideBar, { MobileSideBar } from '@/Components/SideBar';
import platform from '@/Utils/platform';
import { getSearchObj } from '@/Utils/format';

import useContent from './useContent';
import useHighlightAll from './useHighlightAll';
import useNavList from './useNavList';
import usePreview from './usePreview';
import useVideo from './useVideo';


import 'react-photo-view/dist/react-photo-view.css';
import './index.less';

// const ImageError = () => <img src={defaultUrl} alt={translate('SYS_LOADING_ERR')} />
// brokenElement={<ImageError />}

const SplitPaneWrapper = ({ children }) => {
    const search = getSearchObj();
    const SplitProps = {
        collapse: true,
        initialSizes: [1, 7],
        minSizes: [300],
        split: 'vertical',
        resizerOptions: {
            css: {
                width: '1px',
                background: 'rgba(0, 0, 0, 0.1)',
            }
        }
    }

    if (search.hiddensidebar === "1")
        return children

    return <SplitPane {...SplitProps}>
        {children}
    </SplitPane>
}

const NormalProduct = (props) => {

    const { history, location: { pathname } } = props;
    // 获取md内容
    const md = useContent(pathname, history);
    // 预览图片
    const previewUrl = usePreview();
    // 代码高亮
    useHighlightAll(md);
    // 插入内容导航
    useNavList(md);
    // 禁用视频下载
    useVideo(md);

    const __html = marked.parse(md);

    const className = classNames('main-box single-product');

    return <div className={className}>

        <SplitPaneWrapper>
            <NormalSideBar />
            <div className='content-box'>
                <PhotoProvider maskOpacity={0.5} loadingElement={<Spin size="large" />} >
                    <PhotoView src={previewUrl}>
                        <div className='markdown-body' dangerouslySetInnerHTML={{ __html }}></div>
                    </PhotoView>
                </PhotoProvider>
                <div className='nav-anchor'>
                    <ul className='nav-list'>
                    </ul>
                </div>
            </div>
        </SplitPaneWrapper>
    </div>
}

const MobileProduct = (props) => {

    const { history, location: { pathname } } = props;

    // 获取md内容
    const md = useContent(pathname, history);
    // 预览图片
    const previewUrl = usePreview();
    // 代码高亮
    useHighlightAll(md);
    // 禁用视频下载
    useVideo(md);

    const __html = marked.parse(md);

    const className = classNames('main-box single-product');

    return <div className={className}>
        <MobileSideBar />
        <div className='content-box mobile'>
            <PhotoProvider maskOpacity={0.5} loadingElement={<Spin size="large" />} >
                <PhotoView src={previewUrl}>
                    <div className='markdown-body' dangerouslySetInnerHTML={{ __html }}></div>
                </PhotoView>
            </PhotoProvider>
        </div>
    </div>
}

const Product = (props) => {
    return platform.isMobile ? <MobileProduct {...props} /> : <NormalProduct {...props} />
}

export default Product;