
import { getMd } from './request'
import { getCurrentLanguage, getDocsUrl } from '@/Utils/config';
import { formatChapters, formatMenusIndex } from '@/Utils/format';
import {
    setProjectSynch as setProjectToCache,
    setSearchIndex as setSearchIndexToCache,
} from '@/Utils/storage';

const getUrlPrefix = () => `${getDocsUrl()}/${getCurrentLanguage()}`;
const v = new Date().getTime();

// 获取指定产品版本的内容目录
export const getMenuByProduct = (product, version) => {
    return getMd(`${getUrlPrefix()}/${product}/${version}/sidebar.json?v=${v}`).then(res => {
        // 菜单索引，方便搜索
        const menusIndex = formatMenusIndex(res);
        // 侧边栏缓存到本地
        setSearchIndexToCache(menusIndex);

        const result = {
            // 菜单索引
            menusIndex,
            // 格式化侧边栏
            menus: formatChapters(res)
        }
        return result;
    });
}

// 获取内容
export const getContent = (url) => {
    return getMd(`${getDocsUrl()}${url}.md?v=${v}`);
}

// 获取应用信息(iocn、name、homePath、desc、..)
export const getProject = (product, version) => {
    return getMd(`${getUrlPrefix()}/${product}/${version}/project.json?v=${v}`).then(res => {
        // 侧边栏缓存到本地
        setProjectToCache(res);
        // 格式化侧边栏
        return res
    })
}




