import axios from 'axios';
import _ from 'lodash';
import { notification, message } from "antd";

// 状态和错误信息
const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。'
}

// 检查网络错误
export function checkStatus(response) {
    //请求成功
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    //请求失败
    const errortext = codeMessage[response.status] || response.statusText;
    notification.error({
        message: `${"请求错误"} ${response.status}: ${response?.config?.url}`,
        description: errortext,
    });
}

/**
 * 公共请求方法
 * @date 2022-06-10
 * @param {object} options 请求参数
 * @param {object} extra 额外的参数
 * @returns {promise} 请求返回值
 */
function request(options, extra = {}) {
    return axios(options)
        .then(({ data: res }) => {
            const { data } = res || {};
            extra = extra || {};

            // 如果是md文件
            if (extra.isMd) {
                return res;
            }

            // 如果是接口
            if (data?.code === 0) {
                return data
            }

            // 缺省错误提示
            !Boolean(extra.isCustomError) && message.error(data?.message || "");
            return data;

        })
        .catch(error => {
            const response = error.response;
            const status = response.status;
            if (status === 404) {
                throw new Error('not found!');
            }
            checkStatus(response);
            return;
        });
}

/*

options：{
    接口需要的参数
    ...
    
    和接口无关的额外参数
    extra： {
        // 是否自定义处理错误
        isCustomError
    }
}

*/

/**
 * post请求
 * @date 2022-06-10
 * @param {string} url 地址
 * @param {object} options 请求参数
 * @param {object} config 请求配置参数
 * @returns {promise} 请求返回值
 */
function post(url, options = {}, config = {}) {

    const { extra = {}, ...params } = options || {};

    return request({ method: "post", url, data: params, ...config }, extra);

}


/**
 * post请求
 * @date 2022-06-10
 * @param {string} url 地址
 * @param {object} options 请求参数
 * @param {object} config 请求配置参数
 * @returns {promise} 请求返回值
 */
function get(url, options = {}, config = {}) {

    const { extra = {}, ...params } = options || {};

    return request({ method: "get", url, params, ...config }, extra);

}


// 获取md文件内容
function getMd(url, options = {}) {
    return get(url, { ...options, extra: { isMd: true } }, { responseType: 'text' });
}


export {
    getMd,
    get,
    post,
}



