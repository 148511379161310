import React, { useState, useEffect } from 'react';
import { Input, Button, Empty } from 'antd';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { getSearchIndex } from '@/Utils/storage';
import QLink from '@/Components/Link';
import Logo from '@/Components/Logo';
import { translate } from '@/Language/translate';

import './index.less';

// 搜索结果
const ResultItem = ({ str, keyword }) => {
    const index = str.indexOf(keyword);
    const before = str.substr(0, index);
    const after = str.substr(index + keyword.length);
    return <>
        {before}
        <span style={{ color: '#f50' }}>{keyword}</span>
        {after}
    </>
}

// 搜索框
const Search = ({ onCancel, showSearchBtn = true }) => {
    const [result, setResult] = useState([]);
    const [keyword, setKeyWord] = useState('');
    const [searchIndex, setSearchIndex] = useState([]);

    useEffect(() => {
        getSearchIndex().then(setSearchIndex);
    }, [])

    const changeHandler = (evt) => {
        const val = evt.target.value;
        if (!val) {
            setKeyWord('');
            setResult([]);
            return
        }
        const result = searchIndex
            .filter(m => m.name.indexOf(val) > -1)
        setKeyWord(val);
        setResult(result);
    }

    return <div className='search'>
        <Input
            allowClear
            className='input-box'
            placeholder={translate('SYS_SEARCH_PLACEHOLDER')}
            suffix={<SearchOutlined />}
            onChange={changeHandler}
        />
        {showSearchBtn && <Button type='link' onClick={() => onCancel(true)}>{translate('SYS_CANCEL')}</Button>}

        {/* 搜索结果 */}
        {
            keyword && <ul className='result-box'>
                {
                    result.map((item, index) => {
                        return <li key={index} className='result-item'>
                            <QLink path={item.path} name={<ResultItem str={item.name} keyword={keyword} />} />
                        </li>
                    })
                }
                {result.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </ul>
        }

    </div>
}

// LogoBox
const LogoBox = ({ onSearch, project }) => {
    return <div className='logo-box'>
        <Logo project={project} />
        <SearchOutlined className='icon-search' onClick={() => onSearch(false)} />
    </div>
}

// 搜索栏
const SearchBar = ({ project }) => {
    const [visible, setVisible] = useState(true);
    return <div className='search-bar-box'>
        {visible ? <LogoBox onSearch={setVisible} project={project} /> : <Search onCancel={setVisible} />}
    </div>
}

export { Search }

export default SearchBar;


