import Product from '@/Components/Product';
import NotFound from '@/Pages/NotFound';

export const URL = {
    // 异常
    exception: "/exception/404",
    // 产品文档页面
    product: '/:language(en|cn)/:product/:version/:path(.*)',
    // 404
    404: "*"
}

// 产品路由
export const Routers = [
    {
        path: URL.exception,
        component: NotFound,
        exact: true
    },
    {
        path: URL.product,
        component: Product,
        exact: true
    },
    {
        path: URL['404'],
        component: NotFound,
        exact: false
    }
]

export default Routers;

