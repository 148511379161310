import { useEffect, useState } from 'react';
import { getContent } from '@/Utils/api';

const useContent = (pathname, history) => {
    const [md, setMd] = useState('');

    useEffect(() => {
        getContent(pathname).then(data => {
            setMd(data || '');
        }).catch(err => {
            history.push('/exception/404');
        });

    }, [pathname]);

    return md;
}

export default useContent;