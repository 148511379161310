import React, { useEffect, useCallback, useState } from 'react';
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import useCollapse from 'react-collapsed';
import QLink from '@/Components/Link';
import { treeLoop, resetTreeLoop } from '@/Utils/format';
import { getMenuByProduct } from '@/Utils/api';
import platform from '@/Utils/platform';

import './index.less';


const SubMenus = React.forwardRef(({ pathname, onMenuClick, list = [], ...props }, ref) => (

    <ul ref={ref} {...props}>
        {
            list.map(m => {
                const hasChildren = m.children && m.children.length;
                const aCls = classNames('area', 'sub-area',
                    { 'active': m.path && pathname === m.path },
                    { 'has-checked': m.hasChecked },
                );

                const liCls = classNames('sub-panel',
                    { 'has-child': hasChildren },
                );

                const qLiProps = {
                    key: m.key,
                    pathname: pathname,
                    hasChildren: hasChildren,
                    aCls,
                    liCls,
                    item: m,
                    onMenuClick
                }
                return <QLi {...qLiProps} />
            })
        }
    </ul>
))

const QLi = ({ pathname, hasChildren, liCls, aCls, item, onMenuClick = () => { } }) => {
    const [isExpanded, setExpanded] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse({ duration: 500 });

    const _liCls = classNames(liCls,
        { 'expanded': isExpanded }
    );

    const _toggleProps = getToggleProps({
        onClick: () => setExpanded((prevExpanded) => !prevExpanded),
    });

    const props = { onMenuClick, pathname, list: item.children };

    return <li className={_liCls}>
        <div {..._toggleProps}>
            <QLink  {...item} className={aCls} onClick={() => onMenuClick(item)} />
        </div>
        {hasChildren ? <SubMenus {...getCollapseProps()} {...props} /> : null}
    </li>
}

const Menu = (props) => {

    const {
        history,
        match: { params: { product, version } = {} } = {},
        location: { pathname } = {} } = props;

    const [menus, setMenu] = useState([]);

    useEffect(() => {
        getMenuByProduct(product, version).then(({ menus }) => {
            setMenu(menus || [])
        }).catch(err => {
            history.push('/exception/404')
        });
    }, [product, version]);


    const handleMenu = useCallback((item) => {
        if (!item.path) {
            return
        }

        // 重置树的状态
        const _tree = resetTreeLoop(menus);
        const _newMenus = treeLoop(_tree, item.key);

        setMenu(_newMenus);

    }, [menus]);

    const className = classNames('menu-box', { 'mobile': platform.isMobile });
    return <div className={className}>
        <ul>
            {
                menus.map(m => {
                    const hasChildren = m.children && m.children.length;
                    const liCls = classNames('panel',
                        { 'has-child': hasChildren },
                    );
                    const aCls = classNames('area',
                        { 'active': m.path && pathname === m.path },
                        { 'has-checked': m.hasChecked },
                    );

                    const qLiProps = {
                        key: m.key,
                        item: m,
                        hasChildren,
                        liCls,
                        aCls,
                        pathname,
                        onMenuClick: handleMenu
                    }
                    return <QLi  {...qLiProps} />
                })
            }
        </ul>
    </div>
}

export default withRouter(Menu);