import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from '@/Components/Logo';
import QLink from '@/Components/Link';
import MenuContext from '@/Context/MenuContext';
import platform from '@/Utils/platform';
import { getSearchObj } from '@/Utils/format';

import "./index.less";


const Header = ({ project }) => {
    const search = getSearchObj();
    const { visible, setVisible } = useContext(MenuContext);
    const onOpen = useCallback(() => {
        setVisible(!visible)
    }, []);

    if (platform.isMobile && search.hiddensidebar !== "1") {
        return <div className='header-box'>
            <div className='header-center'>
                <span onClick={onOpen}>{visible ? <MenuFoldOutlined style={{ fontSize: '25px' }} /> : <MenuUnfoldOutlined style={{ fontSize: '25px' }} />}</span>
                <span style={{ marginLeft: '30px' }}><Logo project={project} /></span>
            </div>
        </div>
    }
    return null;
}


export default Header;