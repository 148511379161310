import { useEffect } from 'react';

const useForbidCopy = (project) => {

    useEffect(() => {
        // 禁止 右击菜单+选择文本+拷贝+剪切+粘贴
        const eventList = ['contextmenu', 'selectstart', 'copy', 'cut', 'paste'];

        const forbidCopy = _.get(project, 'forbidCopy', true);
        if (!forbidCopy) return

        const handler = (evt) => {
            window.event.preventDefault();
            window.event.returnValue = false;
            return false;
        }

        const handlerKeydown = (e) => {
            const evt = window.event || e;
            // 禁用F12键 和 ctr+shift+I ,防止调出开发者工具
            if (123 === evt.keyCode || (e.ctrlKey == true && e.shiftKey == true && evt.keyCode == 73)) {
                window.event.preventDefault();
                window.event.returnValue = false;
                return false;
            }
        }

        document.addEventListener('keydown', handlerKeydown);
        // 禁止 右击菜单+选择文本+拷贝+剪切+粘贴
        eventList.forEach(evtName => document.addEventListener(evtName, handler));

        return () => {
            document.removeEventListener('keydown', handlerKeydown);
            eventList.forEach(evtName => document.removeEventListener(evtName, handler));
        }
    }, [project]);
}

export default useForbidCopy;


