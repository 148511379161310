import React from 'react';
import QLink from '@/Components/Link';
import { getDocsUrl } from '@/Utils/config';
import { translate } from '@/Language/translate';

import './index.less';


const Logo = ({ project }) => {
    const appName = project.projectName || translate('SYS_COMPANY');
    return <div className='app-box'>
        <div className='logo-box'>
            {project.projectIcon ? <img className='app-logo' src={`${getDocsUrl()}${project.projectIcon}`} alt={appName} />
                : <i className='app-logo-default'></i>}
            <span className='app-name'>{appName}</span>
        </div>
    </div>
}

export default Logo;