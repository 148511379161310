

function handler(evt) {
    const opt = {
        block: 'center',
        behavior: 'smooth'
    }

    if (evt.target.classList.contains('nav-item')) {
        const targetId = evt.target?.dataset?.targetId || '';
        const target = document.getElementById(targetId);
        if (!target) return;
        target.scrollIntoView(opt);
    }
}

function createNavList() {

    try {
        const markdownBody = document.querySelector('.markdown-body');
        const box = document.querySelector('.nav-list');
        const firstChild = markdownBody.childNodes[0];
        if (firstChild?.scrollIntoView) {
            // 初始化滚动状态
            firstChild?.scrollIntoView(true);
        }

        if (!box) return;

        const nodeList = markdownBody.querySelectorAll('h2');
        if (nodeList.length === 0) {
            box.innerHTML = '';
            return
        };

        const linksHtml = [...nodeList]
            .map(n => `<li data-target-id='${n.id}' class='nav-item'>${n.textContent}</li>`).join('');
        box.innerHTML = linksHtml;

        box.removeEventListener('click', handler);
        box.addEventListener('click', handler);

        scrollObserve();
    } catch (error) {
        console.warn('生成导航出错：', error)
    }

}

function activeNav(target, visible) {
    const hNavList = document.querySelectorAll(`[data-target-id`);
    const hNav = document.querySelector(`[data-target-id='${target.id}']`);
    hNavList.forEach(node => node.classList.remove('active'));
    hNav.classList.add('active');
}

function scrollObserve() {
    const hTitle = document.querySelectorAll('.markdown-body h2');
    const navObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            // console.log(entry)
            if (entry.isIntersecting) {
                // 高亮观察器对应的导航菜单
                activeNav(entry.target, entry.isIntersecting)
            }
        })
    })

    // 对所有的标题绑定观察器
    hTitle.forEach(ele => navObserver.observe(ele));
}

export default createNavList;

