import React from 'react';
import { Result, Button } from 'antd';
import { translate } from '@/Language/translate';

import './index.less';


const NotFound = (props) => {
    const { history } = props;
    return <div className='notfound-box main-box'>
        <Result
            status="404"
            title="404"
            subTitle={translate('SYS_NOT_FOUND')}
            extra={<Button type="link" onClick={() => history.go(-2)}>{translate('SYS_GO_BACK')}</Button>}
        />
    </div>
}

export default NotFound;