export default {
    "SYS_COMPANY": "QuantDo Technology",
    "SYS_DOC_TITLE": "QuantDo Official Documents",
    "SYS_CANCEL": "Cancel",
    "SYS_GO_BACK": "go back",
    "SYS_SEARCH_PLACEHOLDER": "Search",
    "SYS_NOT_FOUND": "Sorry, the page you visited does not exist.",
    "SYS_MORE": "More",
    "SYS_LOADING_ERR": "Loading error",

    "HISTORY_VERSION": "History Versions",
    "HISTORY_LIST": "Versions",

    "SYS_PRE_CHAPTER": "Previous",
    "SYS_NEXT_CHAPTER": "Next",

}