import _ from 'lodash';
import { matchPath } from "react-router";
import { URL } from '@/Common/router';
import setConfig, { getCurrentLanguage } from "@/Utils/config";


const match = matchPath(window.location.hash.replace('#', ''), {
    path: URL.product,
    exact: true,
    strict: false
});

const language = _.get(match, 'params.language', getCurrentLanguage());

const options = {
    //语言类型
    language,
    //基础路径       
    baseUrl: process.env.PUBLIC_URL
};

// 设置语言
setConfig(options);



